// @ts-nocheck
import moengage from '@moengage/web-sdk';
import type { IAdapter, InitArguments } from '../event-client';
import { onLoad } from '../utils';
import type { EventPayloads, EventTypes, LoginPayload } from '../events';

const subscribedEvents: Array<EventTypes> = [
	'product-search',
	'checkout-thankyou',
	'add-to-cart',
	'remove-from-cart',
	'product-view',
	'proceed-to-checkout',
	'notify-me',
	'purchase',
	'wishlist-add-product',
];

class Transformer {
	static transformProductSearchEvent(
		data: EventPayloads['product-search'],
	): Record<string, string> {
		const moengage = window.$moengage!;
		moengage.track_event('search', {
			type: 'Search',
			query_string: data.query,
		});
	}

	static transformAddToCartEvent(
		data: EventPayloads['add-to-cart'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		moengage.track_event('add_to_cart', {
			product_name: data.meta_data.product.title,
			product_id: data.meta_data.product.shopify_id,
			product_url: data.source,
			image_url: data.meta_data.product.image.src,
			price: data.price,
			page_name: data.meta_data.product.tags
				.filter(
					(tag) =>
						tag.includes('Women_RDLR') ||
						tag.includes('Men_RDLR') ||
						tag.includes('Kids_RDLR'),
				)
				.map((tag) => tag.replace('RDLR', '').replace(/_/g, '')),
			size: data.meta_data.selectedVariant.title,
			style: data.meta_data.product.tags
				.filter((tag) => tag.includes('style'))
				.map((tag) =>
					tag.replace('style', '').replace(/_/g, '').replace('RDLR', ''),
				),
			category_name: data.meta_data.product.category,
			brand_name: data.meta_data.product.brand,
			discount: data.meta_data.product.compare_at_price
				? data.meta_data.product.compare_at_price - data.price
				: null,
			inventory: data.meta_data.selectedVariant.inventory_quantity,
			quantity: data.quantity,
			checkoutUrl: 'https://laam.pk/cart',
		});
	}
	static transformRemoveFromCartEvent(
		data: EventPayloads['remove-from-cart'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		moengage.track_event('remove_from_cart', {
			product_name: data.meta_data.product.title,
			product_id: data.meta_data.product.shopify_id,
			price: data.price,
			style: data.meta_data.product.tags
				.filter((tag) => tag.includes('style'))
				.map((tag) =>
					tag.replace('style', '').replace(/_/g, '').replace('RDLR', ''),
				),
			category_name: data.meta_data.product.category,
			page_name: data.meta_data.product.tags
				.filter(
					(tag) =>
						tag.includes('Women_RDLR') ||
						tag.includes('Men_RDLR') ||
						tag.includes('Kids_RDLR'),
				)
				.map((tag) => tag.replace('RDLR', '').replace(/_/g, '')),
			size: data.meta_data.product.options.values[0],
			discount: data.meta_data.product.compare_at_price
				? data.meta_data.product.compare_at_price - data.price
				: null,
			brand_name: data.meta_data.product.brand,
			quantity: data.quantity,
			checkoutUrl: 'https://laam.pk/cart',
		});
	}
	static transformProductView(
		data: EventPayloads['product-view'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		moengage.track_event('product_view', {
			product_name: data.meta_data.product.title,
			product_id: data.meta_data.product.shopify_id,
			price: data.meta_data.product.price,
			style: data.meta_data.product.tags
				.filter((tag) => tag.includes('style'))
				.map((tag) =>
					tag.replace('style', '').replace(/_/g, '').replace('RDLR', ''),
				),
			category_name: data.meta_data.product.category,
			page_name: data.meta_data.product.tags
				.filter(
					(tag) =>
						tag.includes('Women_RDLR') ||
						tag.includes('Men_RDLR') ||
						tag.includes('Kids_RDLR'),
				)
				.map((tag) => tag.replace('RDLR', '').replace(/_/g, '')),
			size: data.meta_data.product.options.values[0],
			discount: data.meta_data.product.compare_at_price
				? data.meta_data.product.compare_at_price - data.meta_data.product.price
				: null,
			brand_name: data.meta_data.product.brand,
			inventory: data.meta_data.product.total_inventory_quantity,
		});
	}
	static transformProceedToCheckoutEvent(
		data: EventPayloads['proceed-to-checkout'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		moengage.track_event('proceed_to_checkout', {
			source: window.location.href,
			totalItems: data.itemCount,
			subTotal: data.subTotal,
			currencyCode: data.currencyCode,
			cartItems: data.meta_data.items.map((item) => ({
				product_name: item.productTitle,
				product_id: item.productId,
				price: item.price,
				style: item.tags
					.filter((tag) => tag.includes('style'))
					.map((tag) =>
						tag.replace('style', '').replace(/_/g, '').replace('RDLR', ''),
					),
				page_name: item.tags
					.filter(
						(tag) =>
							tag.includes('Women_RDLR') ||
							tag.includes('Men_RDLR') ||
							tag.includes('Kids_RDLR'),
					)
					.map((tag) => tag.replace('RDLR', '').replace(/_/g, '')),
				size: item.options.values[0],
				discount: item.compareAtPrice ? item.compareAtPrice - item.price : null,
				brand_name: item.vendor,
				quantity: item.quantity,
			})),
		});
	}
	static transformCheckoutThankYouEvent(
		data: EventPayloads['checkout-thankyou'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		moengage.track_event('order_placed', data);
	}

	static transformWishlistAddEvent(
		data: EventPayloads['wishlist-add-product'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		moengage.track_event('wishlist_add_product', {
			product_name: data.meta_data.product.title,
			product_id: data.meta_data.product.shopify_id,
			URL: 'https://laam.pk/products/' + data.meta_data.product.handle,
			comparePrice: data.meta_data.product.compare_at_price,
			price: data.meta_data.product.price,
			inventory: data.meta_data.product.total_inventory_quantity,
			wishlist: data.wishlistName,
			wishlist_url: 'https://laam.pk/wishlists',
		});
	}

	static transformNotifyMe(
		data: EventPayloads['notify-me'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		console.log({ data: data.meta_data });
		moengage.track_event('notify_me', {
			product_name: data.meta_data.product.title,
			product_id: data.meta_data.product.shopify_id,
			price: data.meta_data.product.price,
			style: data.meta_data.product.tags
				.filter((tag) => tag.includes('style'))
				.map((tag) =>
					tag.replace('style', '').replace(/_/g, '').replace('RDLR', ''),
				),
			page_name: data.meta_data.product.tags
				.filter(
					(tag) =>
						tag.includes('Women_RDLR') ||
						tag.includes('Men_RDLR') ||
						tag.includes('Kids_RDLR'),
				)
				.map((tag) => tag.replace('RDLR', '').replace(/_/g, '')),
			category_name: data.meta_data.product.category,
			size: data.meta_data.product.options
				? data.meta_data.product.options.values[0]
				: null,
			discount: data.meta_data.product.compare_at_price
				? data.meta_data.product.compare_at_price - data.meta_data.product.price
				: null,
			brand_name: data.meta_data.product.brand,
			inventory: data.meta_data.product.total_inventory_quantity,
		});
	}
	static transformPurchaseEvent(
		data: EventPayloads['purchase'],
	): Record<string, string | number> {
		const moengage = window.$moengage!;
		moengage.track_event('orderPlaced', {
			totalPrice: data.grandTotal,
			currency: 'PKR',
			paymentType: data.paymentMethod!,
			totalTax: data.totalTax!,
			shipping: data.shipping!,
			discount: data.discount!,
			orderNumber: data.orderNumber!,
			value: data.grandTotal,
			transaction_id: data.transaction_id,
			email: data.email,
			phoneNumber: data.phoneNumber,
		});
	}
}

const TRANSFORMER_MAP = {
	'product-search': Transformer.transformProductSearchEvent,
	'add-to-cart': Transformer.transformAddToCartEvent,
	'remove-from-cart': Transformer.transformRemoveFromCartEvent,
	'product-view': Transformer.transformProductView,
	'proceed-to-checkout': Transformer.transformProceedToCheckoutEvent,
	'checkout-thankyou': Transformer.transformCheckoutThankYouEvent,
	purchase: Transformer.transformPurchaseEvent,
	'wishlist-add-product': Transformer.transformWishlistAddEvent,
	'notify-me': Transformer.transformNotifyMe,
};

export class MoengageAdapter implements IAdapter {
	name: string = 'moengage';

	queue: Array<{ name: EventTypes; data: EventPayloads[EventTypes] }> = [];
	async init(arg: InitArguments['moengage']): Promise<void> {
		await new Promise<void>((resolve, reject) => {
			onLoad(() => {
				moengage.initialize({
					app_id: arg.apiKey,
					cluster: arg.cluster,
					debug_logs: arg.debugLogs,
				});
				let pollerCount = 0;
				const interval = setInterval(() => {
					pollerCount++;
					if (moengage.isMoeLoaded()) {
						resolve();
					}
					if (pollerCount > 5) {
						clearInterval(interval);
						reject();
					}
				}, 500);
			});
		});
		moengage.call_web_push();
		window.$moengage = moengage;
	}
	sendEvent<T extends EventTypes>(name: T, data: EventPayloads[T]): void {
		if (!subscribedEvents.includes(name)) {
			return;
		}
		if (!window.$moengage) {
			this.queue.push({ name, data });
			return;
		}
		this.queue.push({ name, data });
		this.queue.forEach((event) => {
			TRANSFORMER_MAP[event.name](event.data);
		});
		this.queue = [];
	}
	login(data: LoginPayload): void {
		if (!window.$moengage) {
			return;
		}
		const moengage = window.$moengage!;
		// fire a moengage event and store in localStorage
		if (data.subscribeNewsletter?.subscribe) {
			if (data.subscribeNewsletter.usingEmail) {
				moengage.add_unique_user_id(data.email);
				moengage.add_email(data.email);
			} else {
				moengage.add_mobile(data.mobile);
			}
			moengage.track_event('newsletter-subscribed', {
				email: data.email,
				first_name: data.firstName,
				last_name: data.lastName,
				mobile: data.mobile,
			});
			localStorage.setItem('isSubscribed', true);
		}

		if (data.email) {
			moengage.add_unique_user_id(data.email);
			moengage.add_email(data.email);
		}
		moengage.add_first_name(data.firstName);
		moengage.add_last_name(data.lastName);

		moengage.track_event('login', {
			email: data.email,
			first_name: data.firstName,
			last_name: data.lastName,
			mobile: data.mobile,
		});

		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('moengageUserUpdate', data.email);
		}
	}
	logout(): void {
		window.$moengage?.destroy_session();
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('moengageUserUpdate', null);
		}
	}
}
